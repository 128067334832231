import React, {useState} from "react";
import axios from 'axios';
import { Button, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import {BASE_URL} from "../consts";

export default () => {
  const [ msg, setMsg ] = useState(null);
  const { register, handleSubmit, reset, errors, setError } = useForm();

  const onSubmit = data => {
    const webform_id = "landing_page_form";
    setMsg(null);

    axios.post(`${BASE_URL}/webform_rest/submit`, { ...data, webform_id })
      .then(() => {
        setMsg("Successfully sent.");
        reset({});
      })
      .catch(({ response: { data:e } }) => {
        reset(data);
        if (e && e.message && e.error) {
          for (let key in e.error) {
            setError(key, { type: 'validate', message: e.error[key] });
          }
        }
        console.log(e);
      });
  };

  const fields = [
    {
      key: "first_name",
      label: "First Name",
      required: true,
      type: "text",
    },
    {
      key: "last_name",
      label: "Last Name",
      required: true,
      type: "text",
    },
    {
      key: "email",
      label: "Email",
      required: true,
      type: "text",
    },
    {
      key: "phone",
      label: "Phone (Optional)",
      required: false,
      type: "text",
    },
    {
      key: "company",
      label: "Company",
      required: true,
      type: "text",
    },
    {
      key: "vehicle_type",
      label: "Vehicle type",
      required: true,
      type: "textarea",
    },
  ];

  const RawHTML = ({children, className = ""}) =>
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}}
    />;


  const FormComponent = (props) => {
    switch (props.type) {
      case "textarea":
        return <textarea { ...props } reg="" ref={props.reg} />
      default:
        return <input { ...props } reg="" ref={props.reg} />
    }
  };

  return (
    <div className="contact-form mt-3 py-3 px-4 text-white">
      {msg && <Alert color="success">{msg}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          fields.map(({ key, label, required, type }) => (
            <div key={key} className="form-input mb-3">
              <FormComponent
                className="small px-2"
                placeholder={label}
                required={required}
                type={type}
                name={key}
                reg={register({ required })}
              />
              {
                errors[key] && <span className="text-danger small"><RawHTML>{errors[key].message || 'This field is required'}</RawHTML></span>
              }
            </div>
          ))
        }
        <Button color="danger mb-1">Submit</Button>
      </form>
    </div>
  );
};