import React, { useEffect } from "react";

import img from '../images/16x9.png';

export default () => {
  useEffect(() => {
    window.LimelightPlayerUtil.embed({
      "height": "100%",
      "width": "100%",
      "mediaId": "33ce8dffe4dc46589d610e366f2102ca",
      "playerId": "limelight_player_707683",
      "playerForm": "LVPPlayer"

    });
  }, []);

  return (
    <div className="video-embed">
      <img title="" alt="" src={img} />
      <div id="limelight_player_707683" />
    </div>
  );
};