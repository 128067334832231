import React from "react";

import { SITE_NAME } from "../consts";

import logo from "../images/logo.png";

import IconLinkedin from "./IconLinkedin";
import IconFacebook from "./IconFacebook";
import IconTwitter from "./IconTwitter";
import IconYoutube from "./IconYoutube";

export default () => {
  const socialIcons = [
    {
      icon: <IconLinkedin width="1.8em" height="1.8em" />,
      title: "Linkedin",
      href: "https://www.linkedin.com/showcase/vpg-onboard-weighing",
    },
    // {
    //   icon: <IconFacebook width="1.8em" height="1.8em" />,
    //   title: "Facebook",
    //   href: "https://facebook.com",
    // },
    {
      icon: <IconTwitter width="1.8em" height="1.8em" />,
      title: "Twitter",
      href: "https://twitter.com/VPGOnboard",
    },
    {
      icon: <IconYoutube width="1.8em" height="1.8em" />,
      title: "Youtube",
      href: "https://www.youtube.com/channel/UC24fGJ56IT-fysQyjCzW_4w",
    },
  ];

  return (
    <div className="container header">
      <div className="row py-2">
        <div className="col-6 logo"><img title={SITE_NAME} alt={SITE_NAME} src={logo} /></div>
        <div className="col-6 social-icons flex-row-reverse d-flex align-items-center">
          {
            socialIcons.map(({ icon, title, href }, k) => (
              <a key={k} className="mx-1" href={href} title={title} target="_blank">
                { icon }
              </a>
            ))
          }
        </div>
      </div>
    </div>
  );
};