import React from "react"
import '../sass/style.scss';

import { SITE_NAME } from "../consts";
import data from "../data";

import productImg from '../images/loadpro_product_shots_2.png';
import pdfIcon from '../images/pdf_icon.png';

import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

import ContentBlock from "../components/ContentBlock";
import Video from "../components/Video";
import Form from "../components/Form";

export default function Home() {
  return (
    <div id="page-main">
      <Header />
      <Hero />

      <div className="container main-content">
        <div className="row">
          <div className="col-md-7">
            <ContentBlock { ...data.maximize } />
            <Video/>
            <ContentBlock { ...data.benefits } />
          </div>

          <div className="col-md-5 pt-4">
            <div className="text-center">
              <img className="mb-4" src={productImg} title="" alt="" />
            </div>

            <h4 className="text-info text-center py-4">{ SITE_NAME } uses<br />Patented Technology</h4>

            <a target="_blank" href="./LoadPro_Product_Sheet.pdf" className="py-2 text-white bg-info d-flex text-center align-items-center justify-content-center">
              <img src={pdfIcon} title="" alt="" />
              <h4 className="px-3">{ SITE_NAME }<br />Overview</h4>
            </a>

            <h4 className="text-center text-danger mt-4">Contact us for more information or about<br />a free 30 day demo!</h4>
            <div className="text-center text-info font-weight-light">Fill out the form below.</div>

            <Form />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
