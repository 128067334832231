import React from "react";

import hero from "../images/Rectangle5.png";

export default () => {
  return (
    <div className="container hero">
      <div className="row">
        <img title="" alt="" src={hero} />
      </div>
    </div>
  );
};