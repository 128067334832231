import React from "react";

export default ({ title, rows }) => {
  return (
    <div className="content-block">
      <h2 className="text-primary">{ title }</h2>
      <ul className="small">
        {
          rows.map(text => <li>{ text }</li>)
        }
      </ul>
    </div>
  );
};