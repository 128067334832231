import React from "react";

import logo from '../images/VPGlogo_tagline-RGB.svg';

export default () => {
  return (
    <div id="footer">
      <div className="container py-3">
        <div className="row small">
          <div className="col-8">
            <a href="https://vpgsensors.com/">
              <img className="footer-logo" alt="" title="" src={logo}/>
            </a>
          </div>
          <div className="col-4 d-flex flex-column justify-content-center">
            Copyright Vishay Precision Group, Inc.<br />
            All rights reserved.<br />
            <a className="text-white" href="https://vpgsensors.com/legal">Priacy and Legal</a>
          </div>
        </div>
      </div>
    </div>
  );
};