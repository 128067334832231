import {SITE_NAME} from "./consts";

export default {
  maximize: {
    title: "Maximize Your Legal Load and Avoid Fines",
    rows: [
      "Accuracy better than ±2.5% (90% - 100% GVW)",
      "Patented low-cost solid state electronic sensors",
      "Easy installation; no welding or fabrication",
      "Proven solution worldwide on >35,000 vehicles",
      "Overload alarm - audible or visual",
      "Ability to display each axle, axle group, gross vehicle load, and trailer loads",
      "Telematics output",
      "Fit and forget - no need for recalibration",
    ],
  },

  benefits: {
    title: `Additional benefits of the ${SITE_NAME} system:`,
    rows: [
      "Avoid accidents and liabilities",
      "Avoid losing insurance coverage",
      "Keep non-CDL drivers legal",
      "Reduce maintenance costs",
      "Decrease fuel consumption",
    ],
  },
};
